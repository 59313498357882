import React from "react";
import { useSearchContext } from "./Hook/SearchContext";
import { Header, AddressBar, FloatButton } from "./Style/SearchStyles";
import {
  SearchLayout,
  MapView,
  FilterPopup,
  LocationFilter,
  SearchPopup,
  RegisterPreminumPubInfo,
  PubMore,
  MapBanner,
  MapPub,
  LocationSheet,
} from "./Components";

const SearchContent: React.FC = () => {
  const {
    history,
    showRegisterPremium,
    setShowRegisterPremium,
    showSearch,
    setShowSearch,
    showFilter,
    setShowFilter,
    showLocationFilter,
    setShowLocationFilter,
    showStoreList,
    filter,
    cityText,
    provinces,
    cities,
    handleTextSearch,
    handleSelectCity,
    handleClickResult,
    toggleShowList,
    setFilter,
    selectedStore,
    isScrolled,
  } = useSearchContext();

  const handleBackClick = () => history.goBack();
  const handleFloatButtonClick = () => {
    setShowSearch(false);
    toggleShowList();
  };

  return (
    <>
      {showStoreList && (
        <FloatButton onClick={handleFloatButtonClick} id="to-map">
          <img src="/image-web/search/to-map.svg" alt="To Map" id="to-map" />
        </FloatButton>
      )}
      <Header>
        <AddressBar show={showStoreList} isScrolled={isScrolled}>
          <div className="close" onClick={handleBackClick}>
            <img
              src="/image-web/Icon/Back.svg"
              id="홀덤펍 찾기 뒤로가기"
              alt="close"
            />
          </div>
          <input
            className={`box ${!showStoreList ? "map " : ""}flex`}
            readOnly
            placeholder="홀덤 펍 검색"
            onClick={() => setShowSearch(true)}
          />
        </AddressBar>
      </Header>

      {showRegisterPremium && (
        <RegisterPreminumPubInfo
          onClose={() => setShowRegisterPremium(false)}
        />
      )}
      <LocationSheet />
      {showSearch && (
        <SearchPopup
          onSearchText={handleTextSearch}
          onClickResult={handleClickResult}
          onClose={() => setShowSearch(false)}
        />
      )}
      {showFilter && (
        <FilterPopup
          currentFilter={filter}
          onApplyFilter={setFilter}
          onClose={() => setShowFilter(false)}
        />
      )}
      {showLocationFilter && (
        <LocationFilter
          provinces={provinces}
          cities={cities}
          onSelected={handleSelectCity}
          onClose={() => setShowLocationFilter(false)}
        />
      )}
      {showStoreList && selectedStore && <PubMore cafe={selectedStore} />}

      <SearchLayout
        showFilter={showFilter}
        showLocationFilter={showLocationFilter}
        setShowLocationFilter={setShowLocationFilter}
        showStoreList={showStoreList}
        cityText={cityText}
      >
        {!showStoreList && <MapView />}
        <MapBanner />
      </SearchLayout>

      {!showStoreList && selectedStore && <MapPub cafe={selectedStore} />}
    </>
  );
};

export default SearchContent;
